import { useQuery } from '@apollo/client';
import { amplitudeService } from '@bfa/amplitude-events';
import { externalRedirect } from '@bfa/nextjs-common/utils';
import { LoginBannerModule } from 'features/Splash/types';
import {
  GQLChoosePackagePage,
  GQLLayoutContent,
  GQLMembershipCosts,
  GQLMyData,
  GQLQuery,
} from 'gql.generated';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { ABSOLUTE_URLS } from '../../../constants';
import { initLoginEvent } from '../../Splash/utils/analytics';
import { GET_OPTIMIZED_HOMEPAGE } from '../graphql/GET_OPTIMIZED_HOMEPAGE';

type UseOptimizedHomepageResult = {
  login: {
    open: boolean;
    onClick: () => void;
    banner?: LoginBannerModule | null;
    recaptchaEnabled: boolean;
  };
  ctas: {
    handleGetStarted: () => void;
    handleJoinNow: () => void;
    handleChoosePackage: (packageTypes: string[]) => void;
  };
  homeBanner: {
    title: string;
    cta: string;
  };
  homeProgramsSection: {
    title: string;
    subtitle: string;
    disclaimer: string;
    programs?: {
      title: string;
      subtitle: string;
      price: number;
      packages?: string[];
    }[];
  };
  homeHeroSection: {
    image: {
      mobile: string;
      desktop: string;
    };
    title: string;
    subtitle: string;
    cta: string;
  };
} | null;

const prepareHomepageData = (data?: ExtendedGQLQuery) => {
  const offerings = data?.me?.layout?.choosePackagePageResult?.offerings ?? [];
  return {
    homeBanner: {
      title: data?.me?.layout?.homepageBannerSection?.text ?? '',
      cta: data?.me?.layout?.homepageBannerSection?.cta ?? '',
    },
    homeHeroSection: {
      image: {
        mobile: data?.me?.layout?.homepageHeroSection?.mobileImage?.src ?? '',
        desktop: data?.me?.layout?.homepageHeroSection?.desktopImage?.src ?? '',
      },
      title: data?.me?.layout?.homepageHeroSection?.title ?? '',
      subtitle: data?.me?.layout?.homepageHeroSection?.subtitle ?? '',
      cta: data?.me?.layout?.homepageHeroSection?.cta ?? '',
    },
    homeProgramsSection: {
      title: data?.me?.layout?.choosePackagePageResult.title ?? '',
      subtitle: data?.me?.layout?.choosePackagePageResult.subtitle ?? '',
      disclaimer: data?.me?.layout?.choosePackagePageResult.disclaimer ?? '',
      programs: offerings.map((offer) => {
        const isDual =
          (offer?.packageTypes && offer?.packageTypes.length > 1) ?? false;
        const gb = offer?.packageTypes?.[0] === 'MONTHLY';
        const dualPrice =
          (data?.me?.glambagMonthlyPrice?.price?.amount ?? 14) +
          (data?.me?.fullsizeMonthlyPrice?.price?.amount ?? 30);
        const price = isDual
          ? dualPrice
          : gb
          ? data?.me?.glambagMonthlyPrice?.price?.amount
          : data?.me?.fullsizeMonthlyPrice?.price?.amount;

        return {
          title: offer?.title ?? 'Missing Title',
          subtitle: offer?.subtitle ?? 'Misssing Subtitle',
          price: price ?? 0,
          packages: (offer?.packageTypes as string[]) ?? [],
        };
      }),
    },
  };
};

interface ExtendedLayout extends GQLLayoutContent {
  homepageHeroSection?: {
    title?: string;
    subtitle?: string;
    cta?: string;
    mobileImage?: {
      src?: string;
    };
    desktopImage?: {
      src?: string;
    };
  };
  homepageBannerSection?: {
    text?: string;
    cta?: string;
  };
  choosePackagePageResult: GQLChoosePackagePage;
  refreshmentsHomeTabFlag: GQLLayoutContent['eligibleForFeature'];
}

interface ExtendedMyData extends GQLMyData {
  layout: ExtendedLayout;
  glambagMonthlyPrice: GQLMembershipCosts;
  fullsizeMonthlyPrice: GQLMembershipCosts;
}

interface ExtendedGQLQuery extends GQLQuery {
  me: ExtendedMyData;
}

export const useOptimizedHomepage = ({
  loginBanner,
  recaptchaEnabled,
}: {
  loginBanner?: LoginBannerModule | null;
  recaptchaEnabled: boolean;
}): UseOptimizedHomepageResult => {
  const { query } = useRouter();
  const { data, loading, error } = useQuery<ExtendedGQLQuery>(
    GET_OPTIMIZED_HOMEPAGE,
    {
      variables: {
        sid: '',
        cid: 'optimized-homepage',
      },
    }
  );

  const isForceLoginOrPasswordReset =
    query.forceLogin === '1' || query.passwordIsReset === '1';

  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(
    isForceLoginOrPasswordReset
  );

  const triggerLoginEvent = useCallback(initLoginEvent(), []);

  useEffect(() => {
    if (isForceLoginOrPasswordReset) {
      triggerLoginEvent({
        type: 'default',
        label: 'Login Modal Open',
      });
      amplitudeService.login.modalViewed({
        isForceLogin: query.forceLogin === '1',
        isPasswordReset: query.passwordIsReset === '1',
      });
    }
  }, [isForceLoginOrPasswordReset]);

  const handleLoginClick = () => {
    triggerLoginEvent({
      type: 'default',
      label: !isLoginModalOpen ? 'Login Modal Open' : 'Login Modal Close',
    });

    if (!isLoginModalOpen) {
      amplitudeService.login.modalViewed({
        isForceLogin: false,
        isPasswordReset: false,
      });
    } else {
      amplitudeService.login.modalClosed({});
    }

    setIsLoginModalOpen((prev) => !prev);
  };

  const handleJoinNow = () => {
    externalRedirect(
      ABSOLUTE_URLS.SUB_SPA_CHOOSE_PAGE + '?cid=optimized-homepage'
    );
  };
  const handleChoosePackage = (packageTypes: string[]) => {
    externalRedirect(
      ABSOLUTE_URLS.SIGNUP + `?packageTypes=${packageTypes.join(',')}`
    );
  };

  if (loading || error) return null;

  return {
    ...prepareHomepageData(data),
    login: {
      onClick: handleLoginClick,
      open: isLoginModalOpen,
      banner: loginBanner,
      recaptchaEnabled,
    },
    ctas: {
      handleChoosePackage,
      handleJoinNow,
      handleGetStarted: () => {
        document
          .getElementById('home-programs-section')
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      },
    },
  };
};
