import React, { FC } from 'react';

const StoryStreamSection: FC = () => {
  return (
    <>
      <h2
        style={{
          alignItems: 'center',
          textAlign: 'center',
          paddingTop: '30px',
          fontSize: '19px',
          fontWeight: '500 !important',
          margin: '16px 0px',
        }}
      >
        Share your best IPSY moment using the hashtag #IPSY
        <br />
        <br />
      </h2>
      <div
        id="stry-wrapper"
        style={{ paddingBottom: '100px', margin: 'auto', maxWidth: '100%' }}
      ></div>
    </>
  );
};

export default StoryStreamSection;
