import { Box } from '@bfa/common-ui';
import range from 'lodash/range';
import React, { FC } from 'react';

type RatingStarsProps = {
  rating: number;
  limit?: number;
};

const REVIEWS_RATING_LIMIT = 5;

const RatingStars: FC<RatingStarsProps> = ({ rating }) => {
  const ratingOutOfTen = Math.min(
    Math.round(rating * 2),
    REVIEWS_RATING_LIMIT * 2
  );
  const numberOfWholeHearts = Math.floor(ratingOutOfTen / 2);
  const numberOfHalfHearts = ratingOutOfTen % 2;

  return (
    <>
      {range(REVIEWS_RATING_LIMIT).map((i) => {
        let icon = 'icon-ipsy-star-outline';

        if (i < numberOfWholeHearts) {
          icon = 'icon-ipsy-star-filled';
        } else if (i < numberOfWholeHearts + numberOfHalfHearts) {
          icon = 'icon-ipsy-star-half-filled';
        }

        return (
          <Box
            as="span"
            key={i}
            pr={i < REVIEWS_RATING_LIMIT ? '0.5rem' : undefined}
          >
            <span className={icon} />
          </Box>
        );
      })}
    </>
  );
};

export default RatingStars;
