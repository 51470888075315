import { breakpoint } from '@bfa/common-ui';
import styled, { css } from 'styled-components';
import { background } from 'styled-system';

import { GQLResponsiveImage } from '../../../../../gql.generated';
import { HeroSectionType } from '../../../types';

type Props = {
  image: GQLResponsiveImage;
  isLpidPresent: boolean;
  type?: number;
};

/**
 * Styled background hero image for hero section.
 * Styled with .highlightProducts .hero-img.
 * Missing feature: analytics.
 *
 * @param p - Props type.
 * @returns Styled div with background image.
 */
const BackgroundHeroImage = styled.div<Props>`
  background-image: url(${(p) => p.image?.mobile?.src});
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  ${background}
  height: 327px;
  background-position: center;

  @media only screen and (min-width: ${breakpoint('sm')}) {
    ${(p) =>
      p.image.desktop && `background-image: url(${p.image.desktop.src});`}
    ${(p) =>
      !p.isLpidPresent &&
      `
        height: 100%;
        background-position: center;
      `}
  }

  ${(p) =>
    p.isLpidPresent &&
    css`
      background-position: top;
      height: 70vh;
      @media only screen and (min-width: ${breakpoint('sm')}) {
        height: 75rem;
      }
    `};

  ${(p) =>
    p.type === HeroSectionType.DEFAULT &&
    css`
      height: 40.2vh;
    `}
`;

export default BackgroundHeroImage;
