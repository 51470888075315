import { Box, H1, H4, Hidden, Visible } from '@bfa/common-ui';
import React, { FC } from 'react';
import styled from 'styled-components';

import ResponsiveText from '../../../../../components/ResponsiveText';
import { GQLLoggedOutContent } from '../../../../../gql.generated';
import SplashHtmlParser from '../../../utils/SplashHtmlParser';
import GetStartedButton from '../GetStartedButton';

export type PropTypes = Omit<GQLLoggedOutContent, 'heroImage'>;

// TODO: Remove after .ux h1 class is removed.
// This overrides css from ipsy-core-styles.
const NavyH1 = styled(H1).attrs((p) => ({
  style: { color: p.theme.colors.navy },
}))``;

const CreatorIntroText: FC<PropTypes> = ({ headline, subHeading, button }) => {
  return (
    <Box
      color="navy"
      position="absolute"
      top="0"
      mt={[null, '15%']}
      ml={[null, '15%']}
      textAlign={['center', 'left']}
      width={['100%', '45%']}
    >
      <NavyH1 mt={['3rem', 0]} mb="2rem" position="relative">
        {SplashHtmlParser(headline)}
      </NavyH1>

      {subHeading && (
        <H4 my="2rem">
          <ResponsiveText inline parseHtml text={subHeading} />
        </H4>
      )}

      <Visible desktop>
        <GetStartedButton main large>
          {button}
        </GetStartedButton>
      </Visible>
      <Hidden desktop>
        <GetStartedButton main medium>
          {button}
        </GetStartedButton>
      </Hidden>
    </Box>
  );
};

export default CreatorIntroText;
