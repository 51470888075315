import { CmsImage } from '../../../utils/types';

export enum HeroSectionType {
  LPID,
  CREATOR,
  DEFAULT,
}

export interface LoginBannerModule {
  bannerTitle?: string;
  bannerSubtitle?: string;
  bannerCtaText?: string;
  bannerCtaModalTitle?: string;
  bannerCtamodalSubtitle?: string;
  bannerCtaModalItems?: string;
  bannerCtaModalAcknowledgeText?: string;
}

export type HomepageCarouselSlide = {
  backgroundColor?: string;
  cardColor?: string;
  cardTitle?: string;
  description?: string;
  desktopImage?: CmsImage;
  mobileImage?: CmsImage;
  programName?: string;
};

export type Perks = {
  description?: string;
  title?: string;
};

export type HomepageMembershipSection = {
  title?: string;
  subtitle?: string;
  perksTitle?: string;
  children?: {
    carousel?: HomepageCarouselSlide[];
    perks?: Perks[];
  };
};
