import { ApolloClient } from '@apollo/client';
import { logError } from '@bfa/nextjs-common/utils';
import { GQLQuery } from 'gql.generated';
import Script from 'next/script';
import React from 'react';

import GET_STORY_STREAM_ENABLED from '../../graphql/queries/GET_STORY_STREAM_ENABLED';
import { getFeatureToggleFromObject } from '../../utils/featureToggle';

export type StoryStreamScriptProps = {
  id: string;
};
export const StoryStreamScript = ({ id }: StoryStreamScriptProps) => {
  return <Script src={`https://apps.storystream.ai/app/js/${id}.js`} />;
};

export const isStoryStreamFeatureFlagEnabled = async (
  client: ApolloClient<any>,
  featureFlagKey: string
) => {
  const { data, error } = await client.query<GQLQuery>({
    query: GET_STORY_STREAM_ENABLED,
    variables: { key: featureFlagKey },
  });
  const isFeatureFlagEnabled = getFeatureToggleFromObject(
    data?.me,
    'storyStreamFeatureFlagEnabled'
  );
  if (error) {
    logError(
      `[StoryStream] Failed retrieving enabling feature flag. Error=${error}`
    );
  }
  return isFeatureFlagEnabled && !error;
};
