import { Box, Flex, Visible } from '@bfa/common-ui';
import { Config } from '@bfa/next-app-config';
import { GQLMyData } from 'gql.generated';
import { NextPageWithInitialProps } from 'next';
import React from 'react';

import AcquisitionPageHead from '../../../components/AcquisitionPageHead';
import Error from '../../../components/Error';
import Layout from '../../../components/Layout';
import ReCaptchaScript from '../../../components/ReCaptchaScript';
import { HomePage } from '../../../features/Home/pages/Home';
import { getAbsoluteURL } from '../../../utils/seo';
import { StoryStreamScript } from '../../../utils/storyStream';
import Banner from '../../Banners/components/Banner';
import Loading from '../../ExtremeCustomization/pages/Loading';
import CreatorLanding from '../components/CreatorLanding';
import FeaturedSection from '../components/FeaturedSection';
import HeroSection from '../components/HeroSection';
import GetStartedButton from '../components/HeroSection/GetStartedButton';
import WhyChooseSection from '../components/HeroSection/WhyChooseSection';
import HowItWorksSection from '../components/HowItWorksSection';
import LoginModal from '../components/LoginModal';
import MembershipSection from '../components/MembershipSection';
import RefreshmentsHpBanner from '../components/RefreshmentsHpBanner';
import SelectPlan from '../components/SelectPlan';
import StoryStreamSection from '../components/StoryStreamSection';
import getHeroSectionType from './helpers/getHeroSectionType';
import getInitialProps from './helpers/getInitialProps';
import useSplashActions from './hooks/useSplashActions';

export const STICKY_CTA_TEST_ID = 'sticky-cta';
const DEFAULT_TITLE =
  'Personalized Monthly Makeup & Beauty Sample Subscription | IPSY';

export type Props = {
  isBoxycharmSubdomain?: boolean;
  baseUrl?: string;
  lpid?: string;
  referId?: string;
  freeBagInviteId?: string;
  isReferralLp?: boolean;
  isFreeBagLp?: boolean;
  isStoryStreamEnabled: boolean;
};

export interface ExtendedMyData extends GQLMyData {
  id: string;
  abResources: {
    PUBLIC_HIDE_GLAMBAG_FROM_ONBOARDING?: string | null;
    PUBLIC_REFRESHMENTS_REFILLS_CYCLE_OFFSET?: string | null;
  };
}

const Splash: NextPageWithInitialProps<Props> = ({
  baseUrl,
  isBoxycharmSubdomain,
  lpid,
  referId,
  freeBagInviteId,
  isReferralLp,
  isFreeBagLp,
  isStoryStreamEnabled,
  /* eslint-disable sonarjs/cognitive-complexity */
}) => {
  const {
    loading,
    homepage,
    refreshmentsHpBanner,
    selectPlanData,
    banner,
    showLoginModal,
    error,
    onHeaderGetStartedClick,
    homepageMembershipSection,
    isHomepageMembershipSection,
    loginModuleBanner,
    isLoginModalBannerOn,
    recaptchaEnabled,
    isOptimizedHomepageEnabled,
    toggleLoginModal,
    onLoginClick,
  } = useSplashActions(
    isBoxycharmSubdomain,
    lpid,
    referId,
    freeBagInviteId,
    isReferralLp,
    isFreeBagLp
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !homepage) {
    return <Error />;
  }

  const {
    content,
    creatorLanding,
    featured,
    whyChoose,
    howItWorks,
    isGetStartedButtonInMiddle,
  } = homepage;

  const otherHeaderProps = {
    splash: true,
    onLoginClick,
    onJoinClick: onHeaderGetStartedClick,
    useAlternativeButtons: showLoginModal,
    buttonInHeader: true,
    showBoxycharmLogo: false,
  };

  const shouldShowGetStartedButton = !showLoginModal && lpid;
  const loggedOutHomepageStoryStreamId =
    Config.public.misc.loggedOutHPStoryStreamId;
  return (
    <>
      <ReCaptchaScript />
      {isStoryStreamEnabled && loggedOutHomepageStoryStreamId && (
        <StoryStreamScript id={loggedOutHomepageStoryStreamId} />
      )}
      <AcquisitionPageHead
        title={DEFAULT_TITLE}
        urlWithoutQuery={baseUrl && getAbsoluteURL(baseUrl)}
      />
      {isOptimizedHomepageEnabled ? (
        <HomePage
          loginBanner={isLoginModalBannerOn ? loginModuleBanner : null}
          recaptchaEnabled={recaptchaEnabled}
        />
      ) : (
        <Layout
          header={{
            onLogoClick: () => toggleLoginModal(false),
            ...otherHeaderProps,
          }}
          footer
        >
          <LoginModal
            isOpen={showLoginModal}
            onDismiss={() => toggleLoginModal(false)}
            banner={isLoginModalBannerOn ? loginModuleBanner : null}
            recaptchaEnabled={recaptchaEnabled}
          />
          <Flex flexDirection="column">
            {banner && <Banner {...banner} />}
            {refreshmentsHpBanner && (
              <RefreshmentsHpBanner {...refreshmentsHpBanner} />
            )}
            <HeroSection
              type={getHeroSectionType(homepage, lpid)}
              isGetStartedButtonInMiddle={isGetStartedButtonInMiddle}
              {...homepage.content}
            />
            {isStoryStreamEnabled && loggedOutHomepageStoryStreamId && (
              <StoryStreamSection />
            )}
            {featured && <FeaturedSection {...featured} />}
            {creatorLanding && (
              <CreatorLanding {...creatorLanding} button={content.button} />
            )}
            {howItWorks && <HowItWorksSection {...howItWorks} />}
            {whyChoose && <WhyChooseSection {...whyChoose} />}
          </Flex>

          {selectPlanData.showSelectPlan && <SelectPlan {...selectPlanData} />}

          {shouldShowGetStartedButton && (
            <Visible mobile>
              <Box position="fixed" bottom={0} left={0} width="100%" zIndex={1}>
                <GetStartedButton
                  fullWidth
                  main
                  medium
                  type="STICKY"
                  data-testid={STICKY_CTA_TEST_ID}
                >
                  {content.button}
                </GetStartedButton>
              </Box>
            </Visible>
          )}
          {isHomepageMembershipSection && (
            <MembershipSection {...homepageMembershipSection} />
          )}
        </Layout>
      )}
    </>
  );
};

Splash.getInitialProps = getInitialProps;

export default Splash;
