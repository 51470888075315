import { Box, Button } from '@bfa/base-ui';
import { breakpoint, Flex, Text } from '@bfa/common-ui';
import styled from 'styled-components';

export const HomeProgramsContainer = styled(Flex).attrs({
  id: 'home-programs-section',
  paddingX: ['3.2rem', '15rem'],
  flexDirection: 'column',
  as: 'section',
})`
  padding-bottom: 4rem;
  margin-top: -0.05rem;
  @media only screen and (max-width: ${breakpoint('sm')}) {
    background: linear-gradient(180deg, #fda9ca 0 15%, #fff 0 100%);
  }
`;

export const HomeProgramsCardsContainer = styled(Flex).attrs({
  flexDirection: ['column', 'row'],
  justifyContent: ['space-between', 'center'],
})`
  gap: 2.4rem;
  margin-top: 2.4rem;
`;

export const HomeProgramsGetStartedButton = styled(Button).attrs({
  variant: 'primary',
})`
  margin-top: 2.4rem;
`;

export const HomeProgramsSubtitleContainer = styled.div`
  text-align: center;
  margin-top: 1rem;

  p,
  span {
    margin: 0;
    font-size: 1.4rem;
  }
`;

export const HomeProgramsDisclaimerContainer = styled(Box).attrs({
  width: ['100%', '56rem'],
})`
  text-align: center;

  p,
  span {
    margin: 0;
    font-size: 1.2rem;
  }
`;

export const HomeProgramsTitle = styled(Text).attrs({
  bold: true,
  fontSize: ['2.2rem', '2.8rem'],
  marginTop: '1.6rem',
  textAlign: 'center',
})``;

type Props = {
  showSticky?: boolean;
};

export const CtaContainer = styled(Box)<Props>`
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
  position: fixed;
  padding: 1.5rem;
  visibility: ${({ showSticky }) => (showSticky ? 'visible' : 'hidden')};
  opacity: ${({ showSticky }) => (showSticky ? 1 : 0)};
  transition: 250ms;
`;
