import {
  Box,
  Column,
  Container,
  H1,
  H2,
  Hidden,
  Image,
  Row,
  Text,
  Visible,
} from '@bfa/common-ui';
import React, { FC } from 'react';
import ReactHtmlParser from 'react-html-parser';

import RatingStars from '../../../../components/RatingStars';
import {
  GQLCreatorLanding,
  GQLLoggedOutContent,
} from '../../../../gql.generated';
import GetStartedButton from '../HeroSection/GetStartedButton';
import Testimonials from './Testimonials';

type Props = Pick<GQLLoggedOutContent, 'button'> & GQLCreatorLanding;

const CreatorLanding: FC<Props> = ({ gift, header, description, button }) => {
  return (
    <Box bg="white">
      <Container py="4rem">
        <Visible mobile>
          <H1 textAlign="center" mb="2rem">
            {header}
          </H1>
        </Visible>

        <Row>
          <Column sm={5} smOffset={1} xsOffset={1} xs={10}>
            <Image
              src={gift.image.src}
              alt={gift.image.alt}
              aspectRatio={[457, 343]}
            />
          </Column>

          <Column sm={5} xs={12}>
            <Box textAlign={['center', 'left']}>
              <Hidden mobile>
                <H2>{header}</H2>
              </Hidden>

              <Text py="1rem">{ReactHtmlParser(description)}</Text>

              <Box pt={['1.5rem', '2.5rem']} pb={['0.5rem', '1rem']}>
                <GetStartedButton main large>
                  {button}
                </GetStartedButton>
              </Box>

              <Text m="2rem auto" color="coral" fontSize="1.6rem">
                <RatingStars rating={gift.rating} />
              </Text>

              <Testimonials testimonials={gift.testimonials} />
            </Box>
          </Column>
        </Row>
      </Container>
    </Box>
  );
};

export default CreatorLanding;
