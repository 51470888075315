import { GET_LOGIN_FORM_DATA } from '@bfa/accounts-forms';
import { cookieService } from '@bfa/nextjs-common/services';
import { getFirstParam } from '@bfa/nextjs-common/utils';
import { NextPageContextWithApollo } from 'next';

import { GQLLanguageIds } from '../../../../gql.generated';
import CookieName from '../../../../utils/cookies/cookieName';
import isBoxycharmSubdomain from '../../../../utils/isBoxycharmSubdomain';
import { isStoryStreamFeatureFlagEnabled } from '../../../../utils/storyStream';

const LPID_PARAM_NAME = CookieName.LPID_COOKIE_NAME;
const COOKIE_MAX_AGE_IN_SECONDS = 15 * 60; // 15 minutes in seconds

type InitialProps = {
  baseUrl: string;
  lpid?: string;
  referId?: string;
  freeBagInviteId?: string;
  isBoxycharmSubdomain?: boolean;
  isReferralLp: boolean;
  isFreeBagLp: boolean;
  isStoryStreamEnabled: boolean;
};

const getInitialProps = async (
  ctx: NextPageContextWithApollo
): Promise<InitialProps> => {
  const { [LPID_PARAM_NAME]: lpidQuery, refer, freeBagInvite } = ctx.query;

  await ctx.apolloClient.query({
    query: GET_LOGIN_FORM_DATA,
    variables: {
      language: GQLLanguageIds.EN_US,
    },
    context: {
      teamName: 'User Accounts & Identities',
    },
  });

  const lpidCookie = cookieService.getCookie(ctx, LPID_PARAM_NAME);
  // Handle case ?lpid=1&lpid=2
  const singleLpid = getFirstParam(lpidQuery);
  const referId =
    getFirstParam(refer) ||
    cookieService.getCookie(ctx, CookieName.REFER_COOKIE_NAME)?.split('|')[0];
  const freeBagInviteId = getFirstParam(freeBagInvite);

  if (singleLpid && lpidCookie !== singleLpid) {
    cookieService.setCookie(ctx, LPID_PARAM_NAME, singleLpid, {
      maxAge: COOKIE_MAX_AGE_IN_SECONDS,
    });
  }

  const storystreamEnabledFeatureFlagKey = 'STORYSTREAM_ENABLED';
  const isStoryStreamEnabled = await isStoryStreamFeatureFlagEnabled(
    ctx.apolloClient,
    storystreamEnabledFeatureFlagKey
  );

  return {
    baseUrl: ctx?.req?.headers?.host ?? `https://${ctx?.req?.headers?.host}`,
    lpid: singleLpid ?? lpidCookie,
    referId,
    freeBagInviteId,
    isBoxycharmSubdomain: isBoxycharmSubdomain(ctx),
    isReferralLp: !!referId,
    isFreeBagLp: !!freeBagInviteId,
    isStoryStreamEnabled: isStoryStreamEnabled,
  };
};

export default getInitialProps;
