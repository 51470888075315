import { Box, Column, Container, H2, H3, Row, Text } from '@bfa/common-ui';
import React, { FC } from 'react';

import { GQLHowItWorks } from '../../../../gql.generated';

const HowItWorksSection: FC<GQLHowItWorks> = ({ sections }) => (
  <Box as="section" bg="semiTransparentBeige" textAlign="center">
    <Container py="3rem">
      <Row mt={['2rem', '3rem']} mb={['4rem', '5rem']}>
        <Column xs={10} xsOffset={1} sm={8} smOffset={2}>
          <H2 as="div">How IPSY Works</H2>
        </Column>
      </Row>

      <Row className="list-row-component" textAlign="center">
        {sections.map(({ title, description }, step) => (
          <Column key={title} xs={10} xsOffset={1} sm={4} smOffset={0}>
            <Text
              bold
              fontSize={['4.6rem', '5.6rem']}
              color="bloodOrange"
              lineHeight={1}
            >
              {step + 1}
            </Text>
            <H3 mt="1.5rem">{title}</H3>
            <Text as="p" mt="1.5rem" mb="3rem">
              {description}
            </Text>
          </Column>
        ))}
      </Row>
    </Container>
  </Box>
);

export default HowItWorksSection;
