import Head from 'next/head';
import React from 'react';

type PropTypes = {
  title: string;
  urlWithoutQuery?: string;
  description?: string;
};

const DEFAULT_DESCRIPTION = [
  'Personalized makeup and beauty products, exclusive offers, and how-to video tutorials from our IPSY Stylists.',
  'Each month subscribers receive a gorgeous Glam Bag with 5+ products starting at $13/month.',
  'Watch and learn the best tips and tricks from our IPSY Stylists and express your own unique beauty.',
].join(' ');

const AcquisitionPageHead = ({
  title,
  description,
  urlWithoutQuery,
}: PropTypes): JSX.Element => (
  <Head>
    <title>{title}</title>
    {urlWithoutQuery && <link rel="canonical" href={urlWithoutQuery} />}
    <meta name="description" content={description ?? DEFAULT_DESCRIPTION} />
  </Head>
);

export default AcquisitionPageHead;
