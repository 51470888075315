import { Box, Carousel } from '@bfa/common-ui';
import React from 'react';

import { HomepageMembershipSection } from '../../types';
import MemberShipCarouselSlide from './MembershipCarouselSlide';
import {
  MainCarousel,
  PerkContainer,
  Perks,
  PerksSection,
  PerksTitle,
  PerkTitle,
  PinkCheckIcon,
  SubTitle,
  Title,
} from './MembershipSection.styled';

const MembershipSection = ({
  title,
  subtitle,
  perksTitle,
  children,
}: HomepageMembershipSection) => {
  const { carousel, perks } = children || {};

  return (
    <Box>
      {title && <Title data-testid="membership-title">{title}</Title>}
      {subtitle && (
        <SubTitle data-testid="membership-subtitle">{subtitle}</SubTitle>
      )}
      {carousel && (
        <MainCarousel data-testid="membership-carousel">
          <Carousel
            layout="DESKTOP"
            dots
            slidesToShow={1}
            arrows={false}
            infinite={false}
            variableWidth
            centerMode
            centerPadding="5px"
          >
            {carousel?.map((slide) => (
              <MemberShipCarouselSlide key={slide.programName} {...slide} />
            ))}
          </Carousel>
        </MainCarousel>
      )}

      <PerksSection>
        <PerksTitle data-testid="perks-title">{perksTitle}</PerksTitle>
        {perks && (
          <Perks data-testid="membership-perks">
            {perks.map(({ title: perkTitle, description }) => (
              <PerkContainer key={perkTitle}>
                <PinkCheckIcon />
                <Box>
                  {perkTitle && <PerkTitle>{perkTitle}</PerkTitle>}
                  {description && <Box>{description}</Box>}
                </Box>
              </PerkContainer>
            ))}
          </Perks>
        )}
      </PerksSection>
    </Box>
  );
};

export default MembershipSection;
