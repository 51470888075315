import { breakpoint, Visible } from '@bfa/common-ui';
import styled from 'styled-components';

type BoxImageProps = {
  width: string;
  height: string;
  image?: string;
};

export const BoxImage = styled.div<BoxImageProps>`
  @media (min-width: ${breakpoint('sm')}) {
    height: ${(props) => props.height};
    background: no-repeat url(${(props) => props.image}) center center;
    background-size: cover;
  }
  @media (min-width: ${breakpoint('xlg')}) {
    height: 100%;
    padding-bottom: ${(props) =>
      (parseInt(props.height, 10) / parseInt(props.width, 10)) * 100}%;
  }
`;

type TopBannerProps = {
  header?: boolean;
  titleColor?: string;
  giftImage?: boolean;
};

export const TopBanner = styled.div<TopBannerProps>`
  padding: 2rem 1.5rem;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ titleColor }) =>
    titleColor ||
    `linear-gradient(90deg, #f6aea9 0%, rgba(247, 174, 169, 0) 35.9%),
    radial-gradient(50.13% 238.19% at 49.87% 50%, #e0a0f2 0%, #f997d7 97.5%)`};

  @media (min-width: ${breakpoint('sm')}) {
    font-size: 1.6rem;
    padding: ${({ giftImage }) => (giftImage ? '1rem 0' : '2.6rem 0')};
    line-height: ${({ header }) => (header ? '1.8rem' : '3.6rem')};
  }
`;

type TopHeaderProps = {
  headerColor?: string;
};

export const TopHeaderBanner = styled.div<TopHeaderProps>`
  font-family: ${(p) => p.theme.fonts.bold};
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: center;
  background: ${({ headerColor }) =>
    headerColor || `linear-gradient(90deg, #d6c7fa, #d5c7fa)`};
`;

export const LinkContainer = styled.a`
  display: inline-flex;
  font-family: ${(p) => p.theme.fonts.bold};
  margin-left: 0.5rem;

  span {
    margin-left: 1rem;
  }
`;

export const GiftImageContainer = styled(Visible)`
  max-width: 5rem;
  margin-right: 1.5rem;
`;
