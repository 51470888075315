import { breakpoint, color, H1, H6 } from '@bfa/common-ui';
import styled, { css } from 'styled-components';

import GetStartedButton from '../GetStartedButton';

export const LPID_TEST_ID = 'splash-lpid-intro';
export const DEFAULT_TEST_ID = 'splash-default-intro';

/**
 * Non-lpid highlight products intro text container.
 * Styled with .highlightProducts .intro-text class.
 *
 * @param p - Props.
 * @returns Styled Components.
 */
export const IntroTextContainer = styled.div.attrs<{ isLpidPresent?: boolean }>(
  ({ isLpidPresent }) => ({
    // Used for testing, since we don't visual cues to get this
    'data-testid': isLpidPresent ? LPID_TEST_ID : DEFAULT_TEST_ID,
  })
)<{ isLpidPresent?: boolean }>`
  margin: 0 auto;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(p) => !p.isLpidPresent && 'bottom: 0;'}
  top: unset;
  padding: 0 1.5rem;
  @media only screen and (min-width: ${breakpoint('sm')}) {
    display: block;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(0, -50%);
    text-align: left;
    margin-top: 0;
    padding: 0 1rem;
  }

  ${(p) =>
    p.isLpidPresent &&
    css`
      position: absolute;
      top: 0;
      padding: 35% 1rem 0;
      @media only screen and (min-width: ${breakpoint('sm')}) {
        left: 0;
        text-align: center;
      }
    `}
`;

/**
 * Non-lpid highlight products intro text headline.
 * Styled with .highlightProducts .headline and .relative class.
 * TODO: remove style attr, it's used here to overrides '.ux h1' global style.
 */
export const IntroTextHeadline = styled(H1)`
  order: 0;
  letter-spacing: 0.02rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  @media only screen and (min-width: ${breakpoint('sm')}) {
    width: 35%;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
`;

/**
 * Non-lpid highlight products intro text subHeading.
 * Styled with .highlightProducts .sub-heading.
 * TODO: remove style attr, it's used here to overrides '.ux h6' global style.
 *
 * @param p - Themed props.
 * @returns Styled intro text sub heading component.
 */
export const IntroTextSubHeading = styled(H6).attrs({
  as: 'div',
  style: { fontFamily: 'NeueEinstellungRegular' },
})<{ isLpidPresent?: boolean }>`
  font-family: ${(p) => p.theme.fonts.regular};
  font-size: 1.4rem;
  color: ${color('grayDarkest')};
  order: 2;
  margin-bottom: 2rem;
  margin-right: ${(p) => (p.isLpidPresent ? 'auto' : '0')};
  @media only screen and (min-width: ${breakpoint('sm')}) {
    width: ${(p) => (p.isLpidPresent ? '60rem' : '30%')};
    margin-top: 0;
  }
`;

/**
 * Non-lpid highlight products intro text get started button.
 * Styled with .highlightProducts .hero-get-started-button.
 */
export const HeroGetStartedButton = styled(GetStartedButton)`
  min-width: 22rem;
  min-height: 5.2rem;
  order: 1;
  margin-top: 1rem;
  @media only screen and (min-width: ${breakpoint('sm')}) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
`;
