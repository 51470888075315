import { Hidden, Visible } from '@bfa/common-ui';
import React, { FC } from 'react';

import ResponsiveText from '../../../../../components/ResponsiveText';
import {
  GQLHomepageContent,
  GQLLoggedOutContent,
} from '../../../../../gql.generated';
import SplashHtmlParser from '../../../utils/SplashHtmlParser';
import {
  HeroGetStartedButton,
  IntroTextContainer,
  IntroTextHeadline,
  IntroTextSubHeading,
} from './IntroText.styled';

export type PropTypes = Omit<GQLLoggedOutContent, 'heroImage'> &
  Pick<GQLHomepageContent, 'isGetStartedButtonInMiddle'>;

const IntroText: FC<PropTypes> = ({
  headline,
  subHeading,
  button,
  isGetStartedButtonInMiddle,
}) => {
  return (
    <IntroTextContainer>
      <IntroTextHeadline>
        <span>{SplashHtmlParser(headline)}</span>
      </IntroTextHeadline>

      <Visible desktop={isGetStartedButtonInMiddle}>
        <HeroGetStartedButton main large>
          {button}
        </HeroGetStartedButton>
      </Visible>

      {subHeading && (
        <IntroTextSubHeading mt="2rem">
          <ResponsiveText inline parseHtml text={subHeading} />
        </IntroTextSubHeading>
      )}

      <Hidden desktop={isGetStartedButtonInMiddle}>
        <HeroGetStartedButton main medium>
          {button}
        </HeroGetStartedButton>
      </Hidden>
    </IntroTextContainer>
  );
};

export default IntroText;
