import { Hidden, Visible } from '@bfa/common-ui';
import React, { FC } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { GQLResponsiveText } from '../../gql.generated';

export type PropTypes = {
  /** Classname, should be provided by styled component. */
  className?: string;
  /** Text provided by GQL. */
  text: GQLResponsiveText;
  /** If the text should be passed through ReactHtmlParser. */
  parseHtml?: boolean;
  /** Whether to display as inline-block. */
  inline?: boolean;
};

/**
 * Renders the text so that they are displayed in the corresponding viewport.
 *
 * @param props - See PropTypes.
 * @param props.text - Text provided by GQL.
 * @param props.parseHtml - If ReactHtmlParser Should be called.
 * @class
 */
const ResponsiveText: FC<PropTypes> = ({ text, parseHtml, ...rest }) => {
  const parser = parseHtml ? ReactHtmlParser : (value: string): string => value;

  return text.desktop && text.desktop !== text.mobile ? (
    <>
      <Visible {...rest} mobile>
        {parser(text.mobile)}
      </Visible>

      <Hidden {...rest} mobile>
        {parser(text.desktop)}
      </Hidden>
    </>
  ) : (
    <Visible {...rest} mobile tablet desktop>
      {parser(text.mobile)}
    </Visible>
  );
};

export default ResponsiveText;
