import { BannerCarousel, breakpoint, color, Text } from '@bfa/common-ui';
import { useClientConfiguration } from '@bfa/nextjs-common/hooks';
import React, { FC } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { GQLCreatorGiftProduct } from '../../../../../gql.generated';

type Props = Pick<GQLCreatorGiftProduct, 'testimonials'>;

const TestimonialsStyled = styled.div`
  & .slick-slider {
    .slick-dots {
      position: relative;
      margin-top: 3.5rem;
      margin-left: -0.5rem;

      li {
        margin: 0;
      }

      li.slick-active {
        button::before {
          color: ${color('coral')};
        }
      }
    }
  }

  @media only screen and (min-width: ${breakpoint('sm')}) {
    & .slick-slider {
      .slick-dots {
        text-align: left;
      }
    }
  }
`;

const Testimonials: FC<Props> = ({ testimonials }) => {
  const clientConfiguration = useClientConfiguration();
  const isMobile = clientConfiguration.isMobile();

  return (
    <TestimonialsStyled>
      <BannerCarousel layout={isMobile ? 'MOBILE' : 'DESKTOP'}>
        {testimonials.map((testimonial) => (
          <Text color="grayDark" key={testimonial}>
            {testimonial && ReactHtmlParser(testimonial)}
          </Text>
        ))}
      </BannerCarousel>
    </TestimonialsStyled>
  );
};

export default Testimonials;
