import { Box, Button } from '@bfa/base-ui';
import { Visible } from '@bfa/common-ui';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { HomeProgramCard } from './components/HomeProgramCard/HomeProgramCard';
import * as S from './HomePrograms.styles';

type HomeProgramsProps = {
  title?: string;
  subtitle?: string;
  disclaimer?: string;
  cta?: (packageTypes: string[]) => void;
  programs?: {
    title: string;
    subtitle: string;
    price: number;
    packages?: string[];
  }[];
  showSticky?: boolean;
};

export const HomePrograms: React.FC<HomeProgramsProps> = ({
  title,
  subtitle = '',
  cta,
  disclaimer = '',
  programs,
  showSticky,
}) => {
  const [selectedProgram, setSelectedProgram] = useState(0);
  return (
    <>
      <S.HomeProgramsContainer>
        <S.HomeProgramsTitle>{title}</S.HomeProgramsTitle>
        <S.HomeProgramsSubtitleContainer>
          {ReactHtmlParser(subtitle)}
        </S.HomeProgramsSubtitleContainer>
        <S.HomeProgramsCardsContainer>
          {programs?.map((program, index) => (
            <HomeProgramCard
              key={index}
              name={program?.title}
              perks={program?.subtitle}
              price={program?.price}
              selected={selectedProgram === index}
              onClick={() => setSelectedProgram(index)}
            />
          ))}
        </S.HomeProgramsCardsContainer>
        <Visible desktop tablet mobile={!showSticky}>
          <Box display="flex" justifyContent="center">
            <Box width={['100%', '34.5rem']}>
              <S.HomeProgramsGetStartedButton
                onClick={() =>
                  cta?.(programs?.[selectedProgram]?.packages ?? [])
                }
              >
                get started now
              </S.HomeProgramsGetStartedButton>
            </Box>
          </Box>
        </Visible>
        <Visible mobile>
          <S.CtaContainer showSticky={showSticky}>
            <Button
              variant="primary"
              onClick={() => cta?.(programs?.[selectedProgram]?.packages ?? [])}
            >
              get started now
            </Button>
          </S.CtaContainer>
        </Visible>
      </S.HomeProgramsContainer>
      <Box
        display="flex"
        justifyContent="center"
        borderTop={['none', '0.1rem solid #ddd']}
        paddingY={['0', '2rem']}
        paddingX={['3.2rem']}
        marginBottom={['4rem', '0']}
      >
        <S.HomeProgramsDisclaimerContainer>
          {ReactHtmlParser(disclaimer)}
        </S.HomeProgramsDisclaimerContainer>
      </Box>
    </>
  );
};
