import { Box, Image, NextImg, Visible } from '@bfa/common-ui';
import Link from 'next/link';
import React, { FC } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { GQLSimpleImage } from '../../../../gql.generated';
import {
  BoxImage,
  GiftImageContainer,
  LinkContainer,
  TopBanner,
  TopHeaderBanner,
} from './RefreshmentsHpBanner.styled';

type PropTypes = {
  desktopBanner: GQLSimpleImage;
  mobileBanner: GQLSimpleImage;
  topBannerTitle?: string;
  header?: string;
  ctaUrl?: string;
  ctaTitle?: string;
  titleColor?: string;
  headerColor?: string;
  giftImage?: GQLSimpleImage;
  isGWS?: boolean;
};

const RefreshmentsHpBanner: FC<PropTypes> = ({
  desktopBanner,
  mobileBanner,
  topBannerTitle,
  header,
  ctaUrl,
  ctaTitle,
  titleColor,
  headerColor,
  giftImage,
  isGWS,
}) => {
  const mobileRatio: [number, number] = [374, 105];
  const imageProps = {
    width: '160rem',
    height: '8.9rem',
    aspectRatio: mobileRatio,
  };

  return (
    <>
      {topBannerTitle ? (
        <>
          {header && (
            <TopHeaderBanner headerColor={headerColor}>
              {header}
            </TopHeaderBanner>
          )}
          <TopBanner
            titleColor={titleColor}
            header={!!header}
            giftImage={isGWS && !!giftImage?.src}
          >
            {isGWS && giftImage?.src && (
              <GiftImageContainer tablet desktop>
                <NextImg
                  images={[
                    {
                      src: giftImage.src,
                      alt: 'top-banner-image',
                      width: giftImage?.width ?? 50,
                      height: giftImage?.height ?? 48,
                    },
                  ]}
                />
              </GiftImageContainer>
            )}
            <Box>
              {ReactHtmlParser(topBannerTitle)}
              {ctaUrl && ctaTitle && (
                <Link href={ctaUrl} passHref>
                  <LinkContainer>
                    {ctaTitle}
                    <span className="icon-ipsy-caret-right" />
                  </LinkContainer>
                </Link>
              )}
            </Box>
          </TopBanner>
        </>
      ) : (
        <>
          <Visible tablet desktop>
            <BoxImage
              image={desktopBanner.src}
              width={imageProps.width}
              height={imageProps.height}
              data-testid="refreshments-desktop-banner"
            />
          </Visible>
          <Visible mobile>
            <Image
              src={mobileBanner?.src}
              alt={mobileBanner?.alt}
              aspectRatio={imageProps.aspectRatio || undefined}
              data-testid="refreshments-mobile-banner"
            />
          </Visible>
        </>
      )}
    </>
  );
};

export default RefreshmentsHpBanner;
