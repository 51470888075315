import { GQLHomepageContent } from '../../../../gql.generated';
import { HeroSectionType } from '../../types';

const getHeroSectionType = (
  homepage: GQLHomepageContent,
  lpid: string | undefined
): HeroSectionType => {
  const { creatorLanding } = homepage;
  const isCreatorLp = !!creatorLanding;

  if (lpid) {
    return HeroSectionType.LPID;
  }

  if (isCreatorLp) {
    return HeroSectionType.CREATOR;
  }

  return HeroSectionType.DEFAULT;
};

export default getHeroSectionType;
