import { gql } from '@apollo/client';

export const GET_OPTIMIZED_HOMEPAGE = gql`
  query GetOptimizedHomepage($cid: String!, $sid: String!) {
    me {
      layout {
        homepageHeroSection: vcmsAsset(
          structure: "HomepageHeroSection"
          properties: { name: "Optimized Acquisition Flow" }
        )
        homepageBannerSection: vcmsAsset(
          structure: "HomepageBannerSection"
          properties: { name: "Optimized Acquisition Flow" }
        )
        choosePackagePageResult(cid: $cid, sid: $sid) {
          ... on ChoosePackagePage {
            name
            title
            subtitle
            disclaimer
            offerings {
              packageTypes
              title
              subtitle
            }
          }
          ... on ChoosePackagePageRedirect {
            name
            uri
            gift {
              id
              name
              image
            }
          }
        }
      }
      glambagMonthlyPrice: membershipCosts(
        program: GLAMBAG
        period: 1
        cadence: 1
      ) {
        price {
          amount
        }
      }
      fullsizeMonthlyPrice: membershipCosts(
        program: FULLSIZE
        period: 1
        cadence: 1
      ) {
        price {
          amount
        }
      }
    }
  }
`;
