import { Box, Flex, Text } from '@bfa/common-ui';
import styled from 'styled-components';

export const Title = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 3.2rem;
  text-align: center;
  letter-spacing: 0.02rem;
  padding: 0 1rem;
  margin-top: 3.6rem;
  margin-bottom: 2.4rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2.8rem;
    line-height: 4.2rem;
    letter-spacing: 0.04rem;
    padding: 0;
    margin-top: 8.35rem;
    margin-bottom: 4rem;
  }
`;

export const SubTitle = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  margin-bottom: 1.2rem;
`;

export const PerksTitle = styled(Title)`
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-top: 4.1rem;
  margin-bottom: 3.2rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2rem;
    line-height: 3rem;
    margin-top: 11.45rem;
    margin-bottom: 5.65rem;
  }
`;

type BackgroundProps = {
  backgroundColor?: string;
};

export const CardTitle = styled.div<BackgroundProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 0 auto;
  padding: 0.4rem 0;
  width: 66.8vw;
  max-width: 29.5rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.2rem;
    padding: 0.6rem 0;
  }
`;

export const DescriptionSection = styled.div<BackgroundProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: 0 auto;
  width: 66.8vw;
  max-width: 29.5rem;
`;

export const ProgramName = styled.div`
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.grayDarkest};
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0.8rem 0 0.8rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2rem;
    line-height: 3rem;
    padding: 1rem 0 1.2rem;
  }
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.grayDarkest};
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 0 1.6rem 1.6rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.4rem;
    padding: 0 1rem 2rem 1.6rem;
  }
`;

export const ImageContainer = styled.div`
  line-height: 0;
  margin: 0 auto;
  width: 66.8vw;
  max-width: 29.5rem;

  span {
    max-height: 15.6rem;
  }
`;

export const SlideContainer = styled.div`
  width: 70vw;
  max-width: 32.5rem;
  margin: 1.6rem 0 0;
  padding: 0 0 6.1rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 29.5rem;
    padding: 0;
    margin: 0;
  }
`;

export const MainCarousel = styled(Box)`
  margin: 0 auto;
  max-width: 49rem;

  .slick-current {
    ${SlideContainer} {
      width: 77.9vw;
      max-width: 36rem;
      margin: 0;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.md}) {
        max-width: 29.5rem;
        margin: 0;
      }
    }

    ${CardTitle} {
      font-size: 1.2rem;
      padding: 0.6rem 0;
      width: 74.7vw;
      max-width: 33rem;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.md}) {
        max-width: 29.5rem;
        font-size: 1.2rem;
        padding: 0.6rem 0;
      }
    }

    ${ImageContainer} {
      width: 74.7vw;
      max-width: 33rem;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.md}) {
        max-width: 29.5rem;
      }
    }

    ${DescriptionSection} {
      width: 74.7vw;
      max-width: 33rem;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.md}) {
        max-width: 29.5rem;
      }
    }

    ${ProgramName} {
      font-size: 1.8rem;
      line-height: 2.6rem;
      padding: 1rem 0 1.2rem;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.md}) {
        font-size: 2rem;
        line-height: 3rem;
        padding: 1rem 0 1.2rem;
      }
    }

    ${Description} {
      font-size: 1.4rem;
      padding: 0 1rem 2rem 1.6rem;
    }
  }

  &&& {
    .slick-dots {
      .slick-active {
        button:before {
          color: ${({ theme }) => theme.colors.pinkIpsy};
        }
      }
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 94.8rem;

    &&& {
      .slick-track {
        width: 94.8rem !important;
        display: flex;
        justify-content: space-between;
        transform: none !important;

        :before,
        :after {
          display: none;
        }
      }

      .slick-dots {
        display: none !important;
      }

      .slick-list {
        padding: 0 !important;
      }
    }
  }
`;

export const PinkCheckIcon = styled.span.attrs({
  className: 'icon icon-ipsy-check',
})`
  font-weight: 700;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.pinkIpsy};
  margin-right: 1.2rem;
`;

export const PerkContainer = styled(Flex)`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0 2.4rem 1.2rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin: 0;
    max-width: 29.5rem;
  }
`;

export const PerkTitle = styled(Box)`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const PerksSection = styled(Box)`
  margin: 0 auto 2.8rem;
  max-width: 37.5rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0 auto 11.6rem;
    max-width: 94.8rem;
  }
`;

export const Perks = styled(Box)`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    justify-content: space-between;
  }
`;
