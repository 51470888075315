import { Box, H1, Hidden, Text, Visible } from '@bfa/common-ui';
import React, { FC } from 'react';

import ResponsiveText from '../../../../../components/ResponsiveText';
import { GQLLoggedOutContent } from '../../../../../gql.generated';
import SplashHtmlParser from '../../../utils/SplashHtmlParser';
import GetStartedButton from '../GetStartedButton';
import {
  IntroTextContainer,
  IntroTextSubHeading,
} from '../IntroText/IntroText.styled';
import {
  RotatingWord,
  RotatingWordWrapper,
  SubheadingWrapper,
} from './LpidIntroText.styled';

const RotatingWords: FC<{ rotating?: GQLLoggedOutContent['rotating'] }> = ({
  rotating,
}) => {
  if (!rotating) return null;
  const words = rotating.split(';');
  return (
    <RotatingWordWrapper>
      {words.map((word, index) => (
        <RotatingWord key={word} length={words.length} index={index}>
          {word}
        </RotatingWord>
      ))}
    </RotatingWordWrapper>
  );
};

export type PropTypes = Omit<GQLLoggedOutContent, 'heroImage'>;

const LpidIntroText: FC<PropTypes> = ({
  rotating,
  headline,
  subHeading,
  button,
  belowButtonDisclaimer,
}) => {
  return (
    <IntroTextContainer isLpidPresent>
      <H1
        mt="3rem"
        position="relative"
        left={[null, rotating ? '-10rem' : null]}
      >
        <Box color="navy" display="inline">
          {SplashHtmlParser(headline)}
        </Box>
        <RotatingWords rotating={rotating} />
      </H1>

      <div>
        {subHeading && (
          <IntroTextSubHeading
            pt="1.5rem"
            mt={rotating ? '2rem' : undefined}
            mx={[null, 'auto']}
            isLpidPresent
          >
            <SubheadingWrapper>
              <ResponsiveText text={subHeading} parseHtml />
            </SubheadingWrapper>
          </IntroTextSubHeading>
        )}
        <Hidden mobile>
          <Text bold small color="grayMedium" mt="0.5rem" pb="1rem">
            {belowButtonDisclaimer}
          </Text>
        </Hidden>

        <Visible mobile>
          <GetStartedButton main large>
            {button}
          </GetStartedButton>
        </Visible>
        <Hidden mobile>
          <GetStartedButton main medium>
            {button}
          </GetStartedButton>
        </Hidden>

        <Visible mobile>
          <Text small color="grayMedium" mt="2rem">
            {belowButtonDisclaimer}
          </Text>
        </Visible>
      </div>
    </IntroTextContainer>
  );
};

export default LpidIntroText;
