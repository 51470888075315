import React from 'react';
import ReactHtmlParser, { Transform } from 'react-html-parser';

import * as S from './HomeProgramCard.styles';
import { Check, RadioChecked, RadioUnchecked } from './Icons';
type HomeProgramCardProps = {
  selected?: boolean;
  name?: string;
  price?: number;
  perks?: string;
  onClick?: () => void;
};

const transform: Transform = (node) => {
  if (node.type === 'tag' && node.name === 'icon') {
    const pNode = node?.children?.find(
      (n: { type: string; name: string }) => n?.type === 'tag' && n.name === 'p'
    );

    return (
      <>
        <Check />
        <p>{pNode.children[0].data}</p>
      </>
    );
  }
};

export const HomeProgramCard: React.FC<HomeProgramCardProps> = ({
  name,
  price,
  perks,
  selected = false,
  onClick,
}) => {
  return (
    <S.HomeProgramCardContainerRadius>
      <S.HomeProgramCardContainer selected={selected} onClick={onClick}>
        <S.HomeProgramCardContainerHeader>
          <S.HomeProgramCardCheckAndProgramContainer>
            <S.HomeProgramCardProgramTitle>
              {name}
            </S.HomeProgramCardProgramTitle>
            {selected ? <RadioChecked /> : <RadioUnchecked />}
          </S.HomeProgramCardCheckAndProgramContainer>
          <S.HomeProgramCardProgramPrice>
            ${price}
            <S.HomeProgramCardProgramCadence>
              /Month
            </S.HomeProgramCardProgramCadence>
          </S.HomeProgramCardProgramPrice>
        </S.HomeProgramCardContainerHeader>
        {perks && (
          <S.HomeProgramCardContainerPerks>
            {ReactHtmlParser(perks, { transform })}
          </S.HomeProgramCardContainerPerks>
        )}
      </S.HomeProgramCardContainer>
    </S.HomeProgramCardContainerRadius>
  );
};
