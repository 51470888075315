import {
  Box,
  Column,
  Container,
  H1,
  H2,
  Image,
  Row,
  Text,
} from '@bfa/common-ui';
import React, { FC } from 'react';

import { GQLWhyChoose } from '../../../../../gql.generated';

const WHY_CHOOSE_CONTENT = [
  {
    title: 'It’s Totally Personalized',
    description:
      'Take our Beauty Quiz, and we’ll build your a monthly Glam Bag filled with picks you’ll love.',
  },
  {
    title: 'You Can Customize It',
    description:
      'Select the membership for your lifestyle, choose one of your products every month,' +
      'and shop discounted add-ons, too.',
  },
  {
    title: 'Discover New Go-Tos',
    description:
      'Enjoy the latest products from brands you know and discover new up and coming brands.',
  },
];

const WhyChooseSection: FC<GQLWhyChoose> = ({ images }) => {
  if (!images || images.length < 3) {
    return null;
  }

  return (
    <Box bg="white" py="3rem">
      <Container as="section" background="white">
        <Row mb="2rem">
          <Column
            xs={10}
            xsOffset={1}
            sm={8}
            smOffset={2}
            my={['2rem', '3rem']}
          >
            <H1 as="div" textAlign="center">
              Why Choose IPSY?
            </H1>
          </Column>
        </Row>

        <Row justifyContent="center">
          {images.map((image, index) => (
            <Column
              key={WHY_CHOOSE_CONTENT[index].title}
              xs={10}
              sm={4}
              mb={['2rem', '3rem']}
            >
              <Box px={[null, '1.8rem']}>
                <Image alt={image.alt} src={image.src} aspectRatio={[1, 1]} />
              </Box>
              <H2 as="div" mt={['1.5rem', '2.5rem']} textAlign="center">
                {WHY_CHOOSE_CONTENT[index].title}
              </H2>
              <Text textAlign="center" mt="1.5rem" mb="1rem">
                {WHY_CHOOSE_CONTENT[index].description}
              </Text>
            </Column>
          ))}
        </Row>
      </Container>
    </Box>
  );
};

export default WhyChooseSection;
