import { Box } from '@bfa/base-ui';
import { NextImg } from '@bfa/common-ui';
import { useClientConfiguration } from '@bfa/nextjs-common/hooks';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import * as S from './HomeHero.styles';

type HomeHeroProps = {
  title?: string;
  subtitle?: string;
  image?: {
    desktop?: string;
    mobile?: string;
  };
  cta?: {
    title?: string;
    onClick?: () => void;
  };
  banner?: React.ReactNode;
};

export const HomeHero: React.FC<HomeHeroProps> = ({
  title,
  subtitle,
  cta,
  banner,
  image,
}) => {
  const { isMobile } = useClientConfiguration();

  return (
    <S.HomeHeroContainer>
      {banner}
      <Box
        display="flex"
        flexDirection={['column']}
        flexBasis={'45%'}
        justifyContent={['flex-start', 'center']}
        alignItems={['center', 'flex-end']}
        textAlign={['center', 'left']}
        paddingX={['1.5rem']}
      >
        <Box width={['100%', '43rem']}>
          <S.HomeHeroTitle>{ReactHtmlParser(title || '')}</S.HomeHeroTitle>
          <S.HomeHeroSubtitle>
            {ReactHtmlParser(subtitle || '')}
          </S.HomeHeroSubtitle>
          <S.HomeHeroCTA onClick={cta?.onClick}>{cta?.title}</S.HomeHeroCTA>
        </Box>
      </Box>
      <Box marginTop={['4rem']} flexBasis={'55%'} paddingTop={['', '3.4rem']}>
        <NextImg
          {...(isMobile() && { layout: 'fill' })}
          images={[
            {
              src: image?.mobile ?? '',
              alt: 'hero-banner-image',
              width: 414,
              height: 264,
            },
            {
              src: image?.desktop ?? '',
              alt: 'hero-banner-image',
              width: 730,
              height: 755,
            },
          ]}
        />
      </Box>
    </S.HomeHeroContainer>
  );
};
