import { breakpoint, color } from '@bfa/common-ui';
import styled, { keyframes } from 'styled-components';

const rotateWordsAnimation = keyframes`
  0% { opacity: 0; }
  2% { opacity: 0; transform: translateY(-30px); }
  5% { opacity: 1; transform: translateY(0px);}
  10% { opacity: 1; transform: translateY(0px); }
  12% { opacity: 0; transform: translateY(30px); }
  80% { opacity: 0; }
  100% { opacity: 0; }
`;

export const RotatingWord = styled.span<{ length: number; index: number }>`
  width: 20rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  overflow: visible;
  white-space: nowrap;
  color: ${color('navy')};
  animation: ${rotateWordsAnimation} ${(p) => p.length * 2.5}s linear infinite;
  animation-delay: ${(p) => p.index * 2.5}s;

  @media only screen and (min-width: ${breakpoint('md')}) {
    width: 27rem;
  }
`;

export const RotatingWordWrapper = styled.div`
  margin: 0 auto;
  text-indent: 1rem;

  @media only screen and (min-width: ${breakpoint('sm')}) {
    display: inline;
    position: relative;
    text-align: left;
  }
`;

// This component help styled Subheading from CMS that comes in different formats
export const SubheadingWrapper = styled.div`
  padding: 0;
  font-family: ${(p) => p.theme.fonts.bold};
  font-size: 1.8rem;
  color: ${color('navy')};
  ul {
    padding: 0;
  }

  li {
    padding: 0;
    list-style-type: none;
  }

  @media only screen and (min-width: ${breakpoint('sm')}) {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      line-height: 1;
      list-style-type: none;
      flex-grow: 1;
      flex-basis: auto;
      margin: 0.25em 0;
      padding: 0 0.5em;
      text-align: center;

      border-left: 0.2rem solid ${color('grayDarkest')};

      :first-of-type {
        border-left: none;
      }
    }
  }
`;
