import React from 'react';

export const Check: React.FC = () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 3.88852L4.57143 7.7774L11 0.777405"
      stroke="#333333"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RadioChecked: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#D60480" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3013 7.78418C16.2351 7.78418 16.193 7.80169 16.1476 7.84904L10.9993 13.1764L8.50325 10.5876C8.45655 10.5403 8.41439 10.5221 8.34823 10.5221C8.28206 10.5221 8.24055 10.5403 8.19385 10.587L7.19882 11.6164C7.15277 11.6637 7.13525 11.7066 7.13525 11.7753C7.13525 11.8441 7.15277 11.8875 7.19882 11.9342L10.8455 15.7022C10.8916 15.7496 10.9331 15.7677 10.9993 15.7677C11.0661 15.7677 11.1082 15.7496 11.153 15.7029L17.4514 9.19629C17.4968 9.14894 17.5136 9.10548 17.5136 9.03672C17.5136 8.96796 17.4968 8.92515 17.4514 8.87715L16.4544 7.84904C16.4096 7.80169 16.3688 7.78418 16.3013 7.78418Z"
      fill="white"
    />
  </svg>
);

export const RadioUnchecked: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11.5" fill="white" stroke="#767676" />
  </svg>
);
