import { Box } from '@bfa/base-ui';
import styled from 'styled-components';

export const HomeBannerText = styled.div`
  p {
    margin: 0;
    font-size: 1.4rem;
    font-family: ${(p) => p.theme.fonts.bold};
  }
`;

export const HomeBannerContainer = styled(Box).attrs({
  position: 'absolute',
  left: '0',
  top: ['5.8rem', '6.8rem'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: ['space-between', 'center'],
  width: '100%',
  padding: ['1.5rem'],
  minHeight: ['7.2rem'],
  backgroundColor: 'rgba(255,255,255,.6)',
})`
  gap: 2rem;
`;
