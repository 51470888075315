import { Box, Button } from '@bfa/base-ui';
import noop from 'lodash/noop';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import * as S from './HomeBanner.styles';

type HomeBannerProps = {
  title?: string;
  cta?: {
    title?: string;
    onClick?: () => void;
  };
  homeBannerRef?: (node?: Element | null) => void;
};

export const HomeBanner: React.FC<HomeBannerProps> = ({
  title = '',
  cta = { title: '', onClick: noop },
  homeBannerRef,
}) => {
  return (
    <S.HomeBannerContainer ref={homeBannerRef}>
      <Box>
        <S.HomeBannerText>{ReactHtmlParser(title)}</S.HomeBannerText>
      </Box>
      <Box minWidth={['11rem']}>
        <Button variant="primary" small onClick={cta?.onClick}>
          {cta?.title}
        </Button>
      </Box>
    </S.HomeBannerContainer>
  );
};
