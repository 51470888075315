// @team BFA Acquisition
import { withRedirectAuthenticatedUser } from '@bfa/nextjs-common/utils';
import flow from 'lodash/flow';

import { ABSOLUTE_URLS } from '../constants';
import Splash from '../features/Splash/pages';
import withRedirectFreeBagUser from '../features/Splash/utils/withRedirectFreeBagUser';
import { withApolloContext } from '../utils/apollo';

const Page = flow(
  withApolloContext({ ssr: true }),
  withRedirectFreeBagUser,
  withRedirectAuthenticatedUser(ABSOLUTE_URLS.GLAM_BAG, false)
)(Splash);

Page.displayName = 'Splash';

export default Page;
