import noop from 'lodash/noop';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import Layout from '../../../components/Layout';
import LoginModal from '../../../features/Splash/components/LoginModal';
import { LoginBannerModule } from '../../../features/Splash/types';
import { HomeBanner } from '../components/HomeBanner';
import { HomeHero } from '../components/HomeHero';
import { HomePrograms } from '../components/HomePrograms';
import { useOptimizedHomepage } from '../hooks/useOptimizedHomepage';

type HomePageProps = {
  loginBanner?: LoginBannerModule | null;
  recaptchaEnabled: boolean;
};

export const HomePage: React.FC<HomePageProps> = ({
  loginBanner,
  recaptchaEnabled,
}) => {
  const { homeProgramsSection, homeHeroSection, homeBanner, login, ctas } =
    useOptimizedHomepage({
      loginBanner,
      recaptchaEnabled,
    }) || {};

  const otherHeaderProps = {
    splash: true,
    onLoginClick: login?.onClick,
    onJoinClick: ctas?.handleGetStarted,
  };

  const { ref: homeBannerRef, inView: homeBannerInView } = useInView({
    threshold: 0.95,
    initialInView: true,
  });

  return (
    <Layout
      header={{
        ...otherHeaderProps,
      }}
      footer
    >
      <LoginModal
        isOpen={login?.open ?? false}
        onDismiss={login?.onClick ?? noop}
        banner={login?.banner}
        recaptchaEnabled={login?.recaptchaEnabled ?? false}
      />
      <HomeHero
        image={homeHeroSection?.image}
        title={homeHeroSection?.title}
        subtitle={homeHeroSection?.subtitle}
        cta={{
          title: homeHeroSection?.cta,
          onClick: ctas?.handleGetStarted,
        }}
        banner={
          <HomeBanner
            title={homeBanner?.title}
            cta={{
              title: homeBanner?.cta,
              onClick: ctas?.handleJoinNow,
            }}
            homeBannerRef={homeBannerRef}
          />
        }
      />
      <HomePrograms
        programs={homeProgramsSection?.programs}
        title={homeProgramsSection?.title}
        subtitle={homeProgramsSection?.subtitle}
        disclaimer={homeProgramsSection?.disclaimer}
        cta={ctas?.handleChoosePackage}
        showSticky={!homeBannerInView}
      />
    </Layout>
  );
};
