import { Box } from '@bfa/common-ui';
import React, { FC } from 'react';
import styled from 'styled-components';

import {
  GQLHomepageContent,
  GQLLoggedOutContent,
} from '../../../../gql.generated';
import { HeroSectionType } from '../../types';
import BackGroundHeroImage from './BackgroundHeroImage';
import CreatorIntroText from './CreatorIntroText';
import IntroText from './IntroText';
import LpidIntroText from './LpidIntroText';

type HeroSectionPropTypes = {
  type: HeroSectionType;
} & GQLLoggedOutContent &
  Pick<GQLHomepageContent, 'isGetStartedButtonInMiddle'>;

const BackgroundFilling = styled.div`
  position: relative;
  height: 100%;
  background-color: #fbfbf9;
`;

const HeroSection: FC<HeroSectionPropTypes> = ({
  type,
  heroImage,
  ...props
}) => (
  <Box position="relative" height={[null, '75rem']} overflow="hidden">
    {heroImage && (
      <BackgroundFilling>
        <BackGroundHeroImage
          isLpidPresent={
            type === HeroSectionType.CREATOR || type === HeroSectionType.LPID
          }
          image={heroImage}
          type={type}
        />
      </BackgroundFilling>
    )}
    <Box>
      {type === HeroSectionType.CREATOR && <CreatorIntroText {...props} />}
      {type === HeroSectionType.LPID && <LpidIntroText {...props} />}
      {type === HeroSectionType.DEFAULT && <IntroText {...props} />}
    </Box>
  </Box>
);

export default HeroSection;
