import { Box, Button, Column, Flex, H3, Image, Text } from '@bfa/common-ui';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { ABSOLUTE_URLS } from '../../../../constants';
import { logAnalyticsEvent } from '../../../../utils/analytics';
import { SelectPlanType } from '../../pages/hooks/useSplashActions';
import { CustomImage, IconBox, PlanCard } from './index.styled';

const SelectPlanCard: FC<SelectPlanType> = ({
  mostPopular,
  packageTypes,
  program,
  color,
  bgColor,
  banner,
  icon,
  image,
  highlights,
  price,
  button,
}) => {
  const router = useRouter();
  return (
    <Column xs={12} md={4} padding="1rem">
      <PlanCard borderColor={color} mostPopular={mostPopular} bgColor={bgColor}>
        <Flex
          color="white"
          backgroundColor={color}
          fontSize="1.2rem"
          fontWeight="700"
          paddingY="1rem"
          justifyContent="center"
        >
          {icon && (
            <IconBox
              width="1.5rem"
              height="0"
              marginRight="0.5rem"
              paddingBottom="0.5rem"
            >
              <Image
                src={icon.src}
                alt={icon.alt}
                aspectRatio={[1, 1]}
                skipImageOptimization
              />
            </IconBox>
          )}
          <strong>{banner}</strong>
        </Flex>
        <Box position="relative">
          <CustomImage
            src={image.src}
            alt={image.alt}
            aspectRatio={
              image.width && image.height ? [image.width, image.height] : [3, 2]
            }
            skipImageOptimization
          />
        </Box>
        <Box padding="2.4rem">
          <H3 marginY=".5rem">{program}</H3>
          <Box textAlign="left" width="26rem" margin="2.4rem auto">
            {highlights.split(',').map((text, id) => (
              <Box marginBottom="1rem" key={id}>
                <Text
                  as="span"
                  className="icon icon-ipsy-check"
                  fontWeight="700"
                />
                <Text as="span" marginLeft="1rem">
                  {text}
                </Text>
              </Box>
            ))}
          </Box>
          <Box marginY="2.4rem">
            <Text fontSize="1.6rem" as="span">
              <strong>{`$${price}`}</strong>
            </Text>
            <Text fontSize="1.6rem" as="span">
              /month
            </Text>
          </Box>
          <Button
            main
            medium
            fullWidth
            text={button}
            onClick={() => {
              logAnalyticsEvent({
                name: 'SIGNED_OUT_SPLASH_SPA',
                label: `Select Plan Choosed ${packageTypes}`,
              });
              router.push(`${ABSOLUTE_URLS.QUIZ}?packageTypes=${packageTypes}`);
            }}
          />
        </Box>
      </PlanCard>
    </Column>
  );
};

export default SelectPlanCard;
