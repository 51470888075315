export const getExperimentFeatureToggleFromMyData = (
  myData: any,
  keyName: string
): boolean =>
  myData?.abResources && (myData?.abResources as any)[keyName]
    ? (myData?.abResources as any)[keyName].toLowerCase() === 'true'
    : false;

export const getFeatureToggleFromObject = (
  layout: any,
  fieldName: string
): boolean =>
  layout && (layout as any)[fieldName]
    ? String((layout as any)[fieldName]).toLowerCase() === 'true'
    : false;
