import { NextImg } from '@bfa/common-ui';
import React from 'react';

import { HomepageCarouselSlide } from '../../types';
import {
  CardTitle,
  Description,
  DescriptionSection,
  ImageContainer,
  ProgramName,
  SlideContainer,
} from './MembershipSection.styled';

const MemberShipCarouselSlide = ({
  backgroundColor,
  cardColor,
  cardTitle,
  description,
  desktopImage,
  mobileImage,
  programName,
}: HomepageCarouselSlide) => {
  return (
    <SlideContainer>
      {cardTitle && (
        <CardTitle
          backgroundColor={cardColor}
          data-testid="carousel-card-title"
        >
          {cardTitle}
        </CardTitle>
      )}
      {mobileImage && desktopImage && (
        <ImageContainer data-testid="carousel-card-image">
          <NextImg
            images={[
              {
                src: mobileImage?.src || '',
                alt: mobileImage?.alt || '',
                width: mobileImage?.width || 560,
                height: mobileImage?.height || 313,
              },
              {
                src: desktopImage?.src || '',
                alt: desktopImage?.alt || '',
                width: mobileImage?.width || 560,
                height: mobileImage?.height || 313,
              },
            ]}
          />
        </ImageContainer>
      )}
      {programName && description && (
        <DescriptionSection
          backgroundColor={backgroundColor}
          data-testid="carousel-card-description"
        >
          <ProgramName>{programName}</ProgramName>
          <Description>{description}</Description>
        </DescriptionSection>
      )}
    </SlideContainer>
  );
};

export default MemberShipCarouselSlide;
