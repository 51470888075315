import {
  Box,
  Column,
  Container,
  H3,
  H4,
  Image,
  Row,
  Visible,
} from '@bfa/common-ui';
import React, { FC } from 'react';

import { GQLFeatured } from '../../../../gql.generated';

const LOGO_ASPECT_RATIO: [number, number] = [164, 59];

const FeaturedSection: FC<GQLFeatured> = ({ title, logos }) => {
  const mobileEndIndex = -(logos.length % 3) || logos.length;
  const showTitleOnSide = logos.length === 6;

  return (
    <Box bg="white">
      <Container textAlign="center" py={['1rem', '3rem']}>
        <Visible tablet desktop>
          {showTitleOnSide ? (
            <Row>
              <Column xs={2}>
                <H4 as="div" mt="1rem">
                  {title}
                </H4>
              </Column>
              <Column xs={10}>
                {logos.map((logo) => (
                  <Box key={logo.src} width="20%" px="1.5rem">
                    <Image
                      src={logo.src}
                      alt={logo.alt}
                      aspectRatio={LOGO_ASPECT_RATIO}
                    />
                  </Box>
                ))}
              </Column>
            </Row>
          ) : (
            <>
              <Row>
                <Column xs={10} xsOffset={1} sm={8} smOffset={2}>
                  <H3 as="div" mt={['2rem', '3rem']} mb="3rem">
                    {title}
                  </H3>
                </Column>
              </Row>
              <Row>
                {logos.map((logo) => (
                  <Box key={logo.src} width="20%" px="1.5rem" mb="3rem">
                    <Image
                      src={logo.src}
                      alt={logo.alt}
                      aspectRatio={LOGO_ASPECT_RATIO}
                    />
                  </Box>
                ))}
              </Row>
            </>
          )}
        </Visible>

        <Visible mobile>
          <Row>
            <Column xs={10} xsOffset={1} sm={8} smOffset={2}>
              <H3 as="div" mt={['2rem', '3rem']} mb="3rem">
                {title}
              </H3>
            </Column>
          </Row>
          <Row>
            {logos.slice(0, mobileEndIndex).map((logo) => (
              <Column key={logo.src} xs={4} mb="3rem">
                <Image
                  src={logo.src}
                  alt={logo.alt}
                  aspectRatio={LOGO_ASPECT_RATIO}
                />
              </Column>
            ))}
          </Row>
        </Visible>
      </Container>
    </Box>
  );
};

export default FeaturedSection;
