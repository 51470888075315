import { Box } from '@bfa/base-ui';
import { Text } from '@bfa/common-ui';
import styled from 'styled-components';

export const HomeHeroContainer = styled(Box).attrs({
  display: ['', 'flex'],
})<{
  mobile?: string;
  desktop?: string;
}>`
  background: linear-gradient(
      316.1deg,
      rgba(179, 226, 255, 0) 50.48%,
      rgba(179, 226, 255, 0.7) 83.52%
    ),
    linear-gradient(
      55.25deg,
      rgba(255, 184, 153, 0) 53.32%,
      rgba(255, 184, 153, 0.7) 79.34%
    ),
    radial-gradient(
      60.7% 53.84% at 59.55% 50%,
      #fce9e5 0%,
      rgba(252, 233, 229, 0) 87.79%
    ),
    linear-gradient(0deg, #fda9ca, #fda9ca);
`;

export const HomeHeroTitle = styled(Text).attrs({
  fontFamily: 'BebasNeueRegular',
  fontSize: ['4.2rem', '4.8rem'],
  lineHeight: ['4.2rem', '4.8rem'],
})`
  margin-top: 10rem;
  color: #000;
`;

export const HomeHeroSubtitle = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  margin-top: 0.8rem;
`;

export const HomeHeroCTA = styled.button`
  background-color: unset;
  border: 1px solid black;
  padding: 1.2rem 0.95rem;
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fonts.bold};
  font-size: 1.6rem;
  margin-top: 2rem;
`;

export const HomeHeroImage = styled.div<{ src: string }>`
  height: 42vh;
  width: 100vw;
  background-image: url(${(p) => p.src});
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: center center;
`;
