import gql from 'graphql-tag';

export const GET_STORY_STREAM_ENABLED = gql`
  query getFeatureFlags($key: String!) {
    me {
      storyStreamFeatureFlagEnabled: featureFlag(key: $key)
    }
  }
`;
export default GET_STORY_STREAM_ENABLED;
