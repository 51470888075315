import { H1, H2, H3, H4, H5, H6 } from '@bfa/common-ui';
import React, { ReactElement } from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { StyledComponent } from 'styled-components';

export type Node = {
  type: string;
  name: string;
  attribs: Record<string, unknown>;
  children: Node[];
};

const ComponentMap: Record<string, StyledComponent<'div', any>> = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
};

/**
 * Transforms h1-h6 elements into H1-H6 components with div as base element.
 *
 * @param node - ReactHtmlParser node.
 * @param index - Index of node within parent.
 * @returns Transformed node or undefined for default behavior.
 */
const transform = (
  node: Node,
  index: number
): ReactElement | undefined | null => {
  const Component = ComponentMap[node.name];

  if (node.type === 'tag' && typeof Component !== 'undefined') {
    return (
      <Component as="div" key={node.children.toString()} {...node.attribs}>
        {node.children.map((curr) =>
          convertNodeToElement(curr, index, transform)
        )}
      </Component>
    );
  }
  return undefined;
};

const SplashHtmlParser = (html: string) => ReactHtmlParser(html, { transform });

export default SplashHtmlParser;
