import { Box } from '@bfa/base-ui';
import { breakpoint, Text } from '@bfa/common-ui';
import styled from 'styled-components';

export const HomeProgramCardContainerRadius = styled(Box).attrs({
  minWidth: ['31.4rem', '36rem'],
})`
  border-radius: 0.4rem;
  overflow: hidden;
`;

export const HomeProgramCardContainer = styled(Box).attrs({
  minWidth: ['31.3rem', '36rem'],
})<{ selected?: boolean }>`
  padding: 1.6rem;
  background-color: ${(p) => (p.selected ? '#FFEAF6' : p.theme.colors.white)};
  border: 0.2rem solid #dddddd;
  border-radius: 0.4rem;
  border-image: ${({ selected }) =>
    selected &&
    `linear-gradient(
      105.59deg,
      #ffb272 0%,
      #fb83e5 44.5%,
      #a1d8ff 100%
    )
    1;`};
  @media (-moz-touch-enabled: 0), (pointer: fine) {
    &:hover {
      border: 0.2rem solid black;
      cursor: pointer;
    }
  }
`;
export const HomeProgramCardContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: solid 1px #ddd;
`;
export const HomeProgramCardProgramTitle = styled(Text).attrs({
  bold: true,
  fontSize: ['1.6rem', '1.8rem'],
})`
  margin: 0;
`;
export const HomeProgramCardProgramPrice = styled(Text).attrs({
  bold: true,
  fontSize: ['4rem', '6rem'],
  textAlign: 'left',
})`
  margin: 0;
`;

export const HomeProgramCardProgramCadence = styled.span`
  font-family: ${(p) => p.theme.fonts.regular};
  font-size: 1.6rem;
`;

export const HomeProgramCardCheckAndProgramContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.6rem;
  align-items: center;
`;

export const HomeProgramCardContainerPerks = styled.div`
  margin: 1.6rem 0 0;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
  p {
    margin: 0;
    font-size: 1.4rem;
    @media only screen and (min-width: ${breakpoint('sm')}) {
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }
`;
