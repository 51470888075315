import { Box, breakpoint, color, Container, H2, Image } from '@bfa/common-ui';
import styled, { DefaultTheme } from 'styled-components';

type PlanCardProps = {
  mostPopular?: boolean;
  borderColor: keyof DefaultTheme['colors'];
  bgColor: string;
};

export const CustomH2Container = styled(Container)`
  @media only screen and (max-width: ${breakpoint('sm')}) {
    border-top: 1px solid ${color('grayLight')};
  }
`;

export const CustomH2 = styled(H2)`
  color: ${color('navy')} !important;
`;

export const IconBox = styled(Box)`
  div:nth-child(1) {
    bottom: 0.2rem;
  }
`;

export const CustomImage = styled(Image)`
  width: 100%;
`;

export const PlanCard = styled(Box)<PlanCardProps>`
  border-radius: 0.8rem 0.8rem 0 0;
  border: ${(p) => (p.mostPopular ? '0.2rem' : '0.1rem')} solid
    ${(p) => (p.mostPopular ? color(p.borderColor) : p.bgColor)};
  overflow: hidden;
`;
