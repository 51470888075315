import { Box, Container, Row } from '@bfa/common-ui';
import React, { FC, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { SelectPlanType } from '../../pages/hooks/useSplashActions';
import { CustomH2, CustomH2Container } from './index.styled';
import SelectPlanCard from './SelectPlanCard';

export type SelectPlanProps = {
  selectPlanTitle: string;
  selectPlans: Array<SelectPlanType>;
};

const SelectPlan: FC<SelectPlanProps> = ({ selectPlanTitle, selectPlans }) => {
  useEffect(() => {
    if (document && document.body && document.body.parentElement) {
      document.body.parentElement.style['scroll-behavior' as any] = 'smooth';
    }
  }, []);

  return (
    <Box
      as="section"
      textAlign="center"
      id="select-plan"
      paddingTop={['3rem', 0]}
    >
      <Container marginBottom="5.5rem">
        <CustomH2Container
          paddingTop={['4rem', '6rem']}
          paddingBottom={['3rem', '5rem']}
        >
          <CustomH2 margin="0 auto" width={['30rem', '60rem']}>
            {ReactHtmlParser(selectPlanTitle)}
          </CustomH2>
        </CustomH2Container>
        <Row>
          {selectPlans.map((plan) => (
            <SelectPlanCard key={plan.program} {...plan} />
          ))}
        </Row>
      </Container>
    </Box>
  );
};

export default SelectPlan;
